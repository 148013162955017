<template>
  <div class="c-my-music-expand">
    <ul class="search-list">
      <li class="search-list__item" v-for="item of mockList" :key="item.name">

    <p class="text">
      <span class="text__type" v-if="item.type">{{ item.type }}</span>
      <span class="text__name">{{ item.name }}</span>
    </p>

    <div class="filter filter--top" v-if="item.labelWith">
      <p class="filter__label">With</p>
      <div class="filter__label-item-wrap">
        <label-item v-for="l of item.labelWith" :label="l" :key="l" />
      </div>
    </div>
    <!-- 若沒有 labelWith 會跑到上面，需要另外+外距 -->
    <div class="filter" :class="{'filter--top': item.labelWith && !item.labelWithout}" v-if="item.labelWithout">
      <p class="filter__label">Without</p>
      <div class="filter__label-item-wrap">
        <label-item v-for="l of item.labelWithout" :label="l" :key="l" />
      </div>
    </div>

      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import LabelItem from '@/components/Global/LabelItem.vue';

export default defineComponent({
  name: 'MyMusicExpand',
  components: {
    LabelItem,
  },
  setup() {
    const mockList = [
      {
        type: '',
        name: 'happy day',
        labelWith: ['勵志 Inspirational', '孩子般 Childlike'],
        labelWithout: ['調皮 Playful'],
      },
      {
        type: 'Tracks',
        name: 'Star',

        labelWithout: ['調皮 Playful'],
      },
      {
        type: 'Lyrics',
        name: 'Indigo',
        labelWith: ['孩子般 Childlike'],
        labelWithout: ['調皮 Playful'],
      },
      {
        type: 'Lyrics',
        name: 'I love you',

        labelWith: ['孩子般 Childlike'],
        labelWithout: ['調皮 Playful'],
      },
    ];

    return {
      mockList,

    };
  },

});
</script>

<style lang="scss" scoped>
.search-list {

  &__item {

    &+& {
      margin-top: 12px;
    }

    @include padding(10px);
    background-color: $c-white;

    border-radius: 3px;
  }
}

.text {
  &__type {
    margin-right: 5px;
    @include font-style($c-assist3, 14, normal, 0.7px, 20px);

  }

  &__name {

    @include font-style($c-black, 14, 600, 0.7px, 20px);

  }
}

.filter {
  // @include flex(flex-start, flex-start);

  &--top {
    margin-top: 10px;
  }

  &__label {
    width: 45px;
    // margin-left: 30px;
    @include font-style($c-assist4, 12, 600, 0px, 26px);
  }

  &__label-item-wrap {
    // @include flex();
  }
}

</style>
