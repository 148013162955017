<template>
  <div class="c-my-music-expand-preview-tool">
    <ul class="desc-list">
      <li class="desc-list__item" v-for="d of descList" :key="d">
        <p class="desc-list__item__text">{{ d }}</p>
      </li>
    </ul>

    <div class="img-wrap">
      <img class="img-wrap__img" src="@/assets/menuExplore/menu_2.png" />
    </div>

    <div class="music-progress">
      <img class="music-progress__icon" src="@/assets/myMusic/icon_video_music.svg"/>
      <div class="music-progress__wrap">
        <progress-bar :height="3"/>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import ProgressBar from '@/components/Global/ProgressBar.vue';

export default defineComponent({
  name: 'MyMusicExpandPreviewTool',
  components: {
    ProgressBar,
  },
  setup() {
    const descList = ['1. 將曲目新增至任一歌單', '2. 上傳您的影片', '3. 即時預覽影片及曲目的搭配效果'];

    return {
      descList,
    };
  },
});
</script>
<style lang="scss" scoped>
.desc-list {
  &__item {
    & + & {
      margin-top: 4px;
    }

    &__text {
      @include font-style($c-assist4, 14, normal, 0.7px, 24px);
    }
  }
}

.img-wrap {
  width: 100%;
  margin-top: 30px;

  &__img {
    width: 100%;
    vertical-align: bottom;

  }
}

.music-progress {
  width: 100%;
  @include flex();
  margin-top: 20px;

  &__icon {
    flex: none;
    width: 20px;

  }

  &__wrap {
    flex: 1;
    margin-left: 15px;
  }
}

</style>
