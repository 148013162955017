<template>
  <div class="c-my-music-expand-play-log">
    <ul class="cover-name-list">
      <li class="cover-name-list__item" v-for="item of mockList" :key="item.albumName">
        <img class="cover-name-list__item__cover" :src="item.imgPath" />
        <p class="cover-name-list__item__name">{{ item.albumName }}</p>
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import albumCover3 from '@/assets/album/3.jpeg';
import albumCover4 from '@/assets/album/4.jpeg';
import albumCover5 from '@/assets/album/5.jpeg';

export default defineComponent({
  name: 'MyMusicExpandPlayLog',
  setup() {
    const mockList = [
      {
        imgPath: albumCover3,
        albumName: 'Bags of Fun1',
      },
      {
        imgPath: albumCover4,
        albumName: 'Cool world2',
      },
      {
        imgPath: albumCover5,
        albumName: 'Come with me3',
      },
      {
        imgPath: albumCover3,
        albumName: 'Cool4',
      },
      {
        imgPath: albumCover4,
        albumName: 'Bags of Fun5',
      },
      {
        imgPath: albumCover5,
        albumName: 'Come with me6',
      },
      {
        imgPath: albumCover3,
        albumName: 'Cool7',
      },
    ];

    return {
      mockList,
    };
  },
});
</script>
<style lang="scss" scoped>
.cover-name-list {

  &__item {
    @include flex();
    @include padding(5px);
    background-color: $c-white;
    height: 40px;
    border-radius: 3px;

    &+& {
      margin-top: 12px;
    }

    &__cover {
      width: 30px;
      height: 30px;
    }

    &__name {
      margin-left: 16px;
    @include font-style($c-black, 14, 600, 0.7px, 26px);

    }
  }
}
</style>
