<template>
  <div class="c-my-music-expand-playlists">

        <div class="playlists-box">
          <img class="playlists-box__img" src="@/assets/myMusic/icon_favourite.svg" />
          <span class="playlists-box__text">我的最愛</span>
        </div>
        <div class="playlists-box">
          <img class="playlists-box__img" src="@/assets/myMusic/icon_video_music.svg" />
          <span class="playlists-box__text">我的歌單</span>
        </div>

        <ul class="playlists-list">
          <li class="playlists-list__item" v-for="item of mockPlaylists" :key="item.key">
            <div class="playlists-list__item__box">
              <div class="playlists-list__item__box__img"></div>
              <p class="playlists-list__item__box__text">
                <span class="playlists-list__item__box__text__name">{{ item.name }}</span>
                <span class="playlists-list__item__box__text__count">({{ item.count }})</span>
              </p>
            </div>
          </li>
        </ul>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MyMusicExpandPlaylist',
  setup() {
    const mockPlaylists = [
      {
        key: 'fds',
        name: '歌單名稱一',
        count: 12,
      },
      {
        key: 'fdsfdsaf',
        name: '歌單名稱二',
        count: 10,
      },
      {
        key: 'ffasds',
        name: '歌單名稱一',
        count: 12,
      },
      {
        key: 'fdsmgkl',
        name: '歌單名稱一',
        count: 12,
      },
    ];

    return {
      mockPlaylists,

    };
  },
});
</script>
<style lang="scss" scoped>
.playlists-box {
  @include flex();
  cursor: pointer;

  &+& {
    margin-top:22px;
  }

  &__img {
    width: 22px;
  }

  &__text {
    @include font-style($c-black, 14, normal, 1.4px, 26px);
    margin-left: 12px;
  }
}

.playlists-list {
  width: 100%;
  margin-top: 20px;

  &__item {
    width: 100%;
    @include flex();
    @include padding(0 15px);
    border-radius: 5px;
    background-color: $c-white;
    height: 40px;
    cursor: pointer;

    &:hover {
      background-color: $c-assist2;
      .playlists-list__item__box__img {
        background-image: url('~@/assets/myMusic/icon_video_play_active.svg');
      }
    }

    & + & {
      margin-top: 12px;
    }

    &__box {
      @include flex();

      &__img {
        width: 13px;
        height: 13px;
        background-image: url('~@/assets/myMusic/icon_video_play.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      &__text {
        margin-left: 12px;

        &__name {
          @include font-style($c-black, 14, normal, 0.7px, 20px);
        }

        &__count {
          margin-left: 5px;
          @include font-style($c-assist4, 14, normal, 0.7px, 20px);
        }
      }
    }
  }
}
</style>
