<template>
  <div class="ly-menu" :class="[`ly-menu--${themeSetting[headerStyle].bgColor}`]">
    <div class="ly-menu__content">
      <div class="ly-menu__content__menu">
        <div class="menu-logo">
          <router-link to="/">
            <img class="menu-logo__img show-mobile" v-show="headerStyle === 'white'" src="@/assets/mobile/logo.svg" />
            <img
              class="menu-logo__img show-mobile"
              v-show="headerStyle === 'black'"
              src="@/assets/mobile/logo_white_menu.svg"
            />
            <img class="menu-logo__img show-tablet" src="@/assets/home/logo.svg" />
          </router-link>
        </div>

        <ul class="menu-list">
          <li
            class="menu-list__item"
            :class="`menu-list__item--${themeSetting[headerStyle].labelColor}`"
            v-for="item of menuList"
            :key="item.key"
            @click="handleToggle(item.routeName, item.key)"
          >
            <span class="menu-list__item__label">
              {{ item.label }}
            </span>
            <div class="menu-list__item__hover-label-box">
              <span class="menu-list__item__hover-label-box__hover-label">
                {{ item.hoverLabel }}
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div class="ly-menu__content__funtion">
        <div class="show-mobile">
          <mobile-header-function-list />
        </div>
        <div class="show-tablet">
          <menu-function-list :icon-color="headerStyle === 'black' ? 'white' : 'black'" />
        </div>
      </div>
    </div>

    <div class="menu-expand-content" @mouseleave="closeInnerContent">

      <Transition name="slide-fade">
        <menu-explore v-show="menuStatus.explore" @closeInnerContent="closeInnerContent" />
      </Transition>
    </div>

    <div class="my-music" v-if="isLogin" @click="openMyMusic">
      <img class="my-music__icon" src="@/assets/icon/icon_open.svg" />
      <p class="my-music__text">MY MUSIC</p>
    </div>

    <menu-search />
  </div>
</template>
<script>
import {
  defineComponent, ref, reactive, computed,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import menuList from '@/config/menuList';
import MenuFunctionList from '@/layout/MenuFunctionList.vue';
import MenuSearch from '@/layout/MenuSearch.vue';
import MenuExplore from '@/views/Home/MenuExplore.vue';
import MobileHeaderFunctionList from '@/layout/MobileHeaderFunctionList.vue';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'Menu',
  components: {
    MenuFunctionList,
    MenuSearch,
    MenuExplore,
    MobileHeaderFunctionList,
  },
  emits: ['handleMyMusicExpand'],
  setup(props, { emit }) {
    const menuStatus = reactive({
      explore: false,
      licensing: false,
      contract: false,
    });

    const menuExpandKey = ref('');
    const route = useRoute();
    const router = useRouter();
    const siteStore = useSiteStore();

    const themeSetting = {
      black: {
        labelColor: 'white',
        bgColor: 'black',
      },
      white: {
        labelColor: 'black',
        bgColor: 'white',
      },
    };

    const handleToggle = (name, key) => {
      if (name) {
        router.push({ name });
      } else {
        menuExpandKey.value = key;
        menuStatus[menuExpandKey.value] = !menuStatus[menuExpandKey.value];
      }
    };

    const closeInnerContent = () => {
      console.log('1');
      menuStatus[menuExpandKey.value] = false;
    };

    const headerStyle = computed(() => {
      if (route.meta.layout?.headerStyle) return route.meta.layout.headerStyle;
      return 'white';
    });

    const openMyMusic = () => {
      emit('handleMyMusicExpand', true);
    };

    return {
      menuList,
      menuStatus,
      handleToggle,
      themeSetting,
      // themeType,
      closeInnerContent,
      headerStyle,
      isLogin: computed(() => siteStore.isLogin),

      openMyMusic,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/animate.scss';
.ly-menu {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zi-fixed-header;
  box-sizing: border-box;
  @include flex(center);

  &--white {
    background-color: $c-white;
    border-bottom: $c-assist5 1px solid;
  }

  &--black {
    background-color: $c-black;
  }

  &__content {
    @include max-width(1700);
    @include flex(space-between);

    &__menu {
      @include flex();
    }

    &__funtion {
      display: inline-block;
    }
  }
}

.menu-expand-content {
  width: 100%;
  @include position(tl, calc(100% + 1px), 0);
  max-height: calc(90vh - 53px);
  overflow-y: auto;
  box-shadow: 0 3px 3px rgba($c-black, 0.1);
}
.menu-logo {
  margin-right: 56px;

  &__img {
    &--tablet {
      display: none;
    }
  }
}
.menu-list {
  display: none;
  @include padding(14px 0);

  &__item {
    width: 95px;
    height: 24px;
    cursor: pointer;
    display: inline-block;
    @include inline-flex(center);

    &--white {
      color: $c-white;
    }

    &--black {
      color: $c-black;
    }

    & + & {
      margin-left: 64px;
    }

    &__label {
      @include font-size(18);
    }

    &__hover-label-box {
      display: none;
    }
  }
}

.menu-list__item {
  &:hover {
    .menu-list__item__label {
      display: none;
    }

    .menu-list__item__hover-label-box {
      display: inline-block;

      &__hover-label {
        @include font-size(18);
        position: relative;
        display: inline-block;
        z-index: 1;

        &::after {
          content: '';
          background-image: url('~@/assets/icon/highlight_3.png');
          @include position(br, -5px, -10px);
          width: 60px;
          height: 10px;
          display: inline-block;
          z-index: -1;
        }
      }
    }
  }
}

.my-music {
  display: none;
  // @include flex(center);
  // // @include position(tr, calc(100% + 0px), 78px);
  // @include position(tr, 80px, 78px);
  // width: 164px;
  // height: 60px;
  // border-bottom-left-radius: 10px;
  // border-bottom-right-radius: 10px;
  // box-shadow: 0px 3px 10px rgba($c-black, 0.16);

  // @extend %bg-gradient-90deg;

  // &__icon {
  //   width: 24px;
  //   vertical-align: bottom;
  // }

  // &__text {
  //   margin-left: 13px;
  //   @include font-style($c-white, 16, 400, 0.8);
  //   cursor: pointer;
  // }
}

.show-tablet {
  display: none;
}

@media screen and (min-width: $tablet) {
  .show-mobile {
    display: none;
  }

  .show-tablet {
    display: block;
  }

  .ly-menu {
    width: 100%;
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zi-fixed-header;
    box-sizing: border-box;
    @include flex(center);

    &--white {
      background-color: $c-white;
      border-bottom: $c-assist5 1px solid;
    }

    &--black {
      background-color: $c-black;
    }

    &__content {
      @include max-width(1700);
      @include flex(space-between);

      &__menu {
        @include flex();
      }

      &__funtion {
        display: inline-block;
      }
    }
  }

  .menu-expand-content {
    width: 100%;
    @include position(tl, calc(100% + 1px), 0);
    max-height: calc(90vh - 53px);
    overflow-y: auto;
    box-shadow: 0 3px 3px rgba($c-black, 0.1);
  }
  .menu-logo {
    margin-right: 56px;

    &__img {
      &--mobile {
        display: none;
      }

      &--tablet {
        display: inline-block;
      }
    }
  }
  .menu-list {
    display: block;
    @include padding(14px 0);

    &__item {
      width: 95px;
      height: 24px;
      cursor: pointer;
      display: inline-block;
      @include inline-flex(center);

      &--white {
        color: $c-white;
      }

      &--black {
        color: $c-black;
      }

      & + & {
        margin-left: 64px;
      }

      &__label {
        @include font-size(18);
      }

      &__hover-label-box {
        display: none;
      }
    }
  }

  .menu-list__item {
    &:hover {
      .menu-list__item__label {
        display: none;
      }

      .menu-list__item__hover-label-box {
        display: inline-block;

        &__hover-label {
          @include font-size(18);
          position: relative;
          display: inline-block;
          z-index: 1;

          &::after {
            content: '';
            background-image: url('~@/assets/icon/highlight_3.png');
            @include position(br, -5px, -10px);
            width: 60px;
            height: 10px;
            display: inline-block;
            z-index: -1;
          }
        }
      }
    }
  }

  .my-music {
    @include flex(center);
    // @include position(tr, calc(100% + 0px), 78px);
    @include position(tr, 220px, -52px);
    width: 164px;
    height: 60px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 3px 10px rgba($c-black, 0.16);
    transform: rotate(90deg);

    @include bg-gradient-90deg();

    &__icon {
      width: 24px;
      vertical-align: bottom;
    }

    &__text {
      margin-left: 13px;
      @include font-style($c-white, 16, 400, 0.8);
      cursor: pointer;
    }
  }
}
</style>
