<template>
  <div class="ly-my-music-expand" @click="closeMyMusic">
    <div
      class="ly-my-music-expand__content"
      :class="{ 'ly-my-music-expand__content--actived': myMusicExpand }"
      @click.stop
    >
      <div class="ly-my-music-expand__content__header my-music-header">
        <p class="my-music-header__my-music">MY MUSIC</p>
        <div class="my-music-header__close">
          <img
            class="my-music-header__close__img"
            src="@/assets/searchResults/icon_popup_close.svg"
            @click="closeMyMusic"
          />
        </div>
      </div>
      <div class="ly-my-music-expand__content__body my-music-body">
        <div class="my-music-body__function-icons">
          <function-icons :data="myMusicTabs" isMusicExpand v-model:activeFunctionName="activeFunctionName" />
        </div>
        <div class="my-music-body__title">
          <p class="my-music-body__title__text">{{ titleMapping[activeFunctionName] }}</p>
        </div>

        <div class="my-music-body__active-function">
          <component :is="activeFunctionName" />
        </div>
      </div>
      <div class="ly-my-music-expand__content__footer my-music-footer">
        <mi-button @click="toMyMusic">{{ activeFunctionName === 'preview-tool' ? '開始使用' : 'View all' }}</mi-button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import MiButton from '@/components/Global/MiButton.vue';
import FunctionIcons from '@/components/MyMusic/FunctionIcons.vue';
import Playlists from '@/components/MyMusicExpand/Playlists.vue';
import SearchHistory from '@/components/MyMusicExpand/SearchHistory.vue';
import PlayHistory from '@/components/MyMusicExpand/PlayHistory.vue';
import DownloadHistory from '@/components/MyMusicExpand/DownloadHistory.vue';
import MyNote from '@/components/MyMusicExpand/MyNote.vue';
import PreviewTool from '@/components/MyMusicExpand/PreviewTool.vue';
import { mobileTabs, pcTabs } from '@/config/myMusicBaseLayoutConfig';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'MyMusicExpand',
  components: {
    MiButton,
    FunctionIcons,
    Playlists,
    SearchHistory,
    PlayHistory,
    DownloadHistory,
    MyNote,
    PreviewTool,
  },
  props: {
    myMusicExpand: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:myMusicExpand'],
  setup(props, { emit }) {
    const siteStore = useSiteStore();
    const router = useRouter();
    const activeFunctionName = ref('playlists');

    const titleMapping = {
      playlists: '所有歌單',
      'search-history': '搜尋紀錄',
      'play-history': '播放紀錄',
      'download-history': '下載紀錄',
      'my-note': '我的筆記',
      'preview-tool': '影音結合體會',
    };

    const closeMyMusic = () => {
      emit('update:myMusicExpand', false);
    };

    const toMyMusic = () => {
      router.push({ name: `my-music-${activeFunctionName.value}` });
      activeFunctionName.value = 'playlists'; // 關閉前跳 playlist
      closeMyMusic();
    };

    const myMusicTabs = computed(() => {
      if (siteStore.deviceType.includes('sm-pc')) {
        return {
          list: pcTabs,
        };
      }

      return {
        list: mobileTabs,
      };
    });

    return {
      closeMyMusic,
      myMusicTabs,
      toMyMusic,
      activeFunctionName,
      titleMapping,
    };
  },
});
</script>
<style lang="scss" scoped>
.ly-my-music-expand {
  width: 100%;
  height: 100%;
  background-color: rgba($c-black, 0.5);
  @include flex(flex-end);
  // animation: contentSlideOut 0.3s  forwards;

  &__content {
    // width: 100%;
    width: 400px;
    height: 100%;
    background-color: $c-assist1;
    @include flex(flex-start, flex-start, column);
    animation: contentSlideOut 0.3s  forwards;

    &--actived {
      animation: contentSlideIn 0.3s forwards;
    }
  }
}

@keyframes contentSlideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes contentSlideOut {
  from {
    transform: translateX(0%);
    }
  to {
    transform: translateX(100%);
  }
}

.my-music-header {
  flex: none;
  height: 60px;
  width: 100%;
  @include flex(space-between);
  @include padding(5px 0 5px 25px);
  @extend %bg-gradient-90deg;

  &__my-music {
    @include font-style($c-white, 16, normal, 0.8px, 20px);
  }

  &__close {
    // width: 30px;
    cursor: pointer;

    &__img {
      width: 100%;
      vertical-align: bottom;
    }
  }
}

.my-music-body {
  flex: 1;
  width: 100%;
  @include padding(43px 24px);
  @include flex(flex-start, flex-start, column);
  overflow: hidden;

  &__function-icons {
    width: 100%;
    flex: none;
    height: auto;
    margin-bottom: 30px;
    flex: none;
    height: auto;
  }

  &__title {
    width: 100%;
    flex: none;
    height: auto;
    @include padding(0 0 12px 0);
    border-bottom: 1px solid $c-assist3;
    margin-bottom: 20px;

    &__text {
      @include font-style($c-black, 18, bold, 1.8px, 26px);
    }
  }

  &__active-function {
    width: 100%;
    flex: 1;
    overflow: auto;
  }
}

.my-music-footer {
  text-align: center;
  @include padding(0 0 50px);
  flex: none;
  width: 100%;
}
</style>
