<template>
  <div class="c-my-music-expand-my-note">
    <ul class="my-note-list">
      <li class="my-note-list__item" v-for="i of 3" :key="i">
        <div class="cover-delete-wrap">
          <div class="cover-name">
            <img class="cover-name__cover" src="@/assets/explore/playlists_img.jpg" />
            <p class="cover-name__name">Bags of Fun</p>
          </div>
          <img class="delete" src="@/assets/searchResults/icon_popup_note_delete.svg" />
        </div>
        <div class="note-time-edit">
          <div class="note-time">
            <p class="note-time__note-text">這是備註文字</p>
            <p class="note-time__time-text">14 Sep 2021 11:24 am</p>
          </div>
          <div class="edit">
            <span class="edit__text">Edit</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MyMusicExpandMyNote',
});
</script>
<style lang="scss" scoped>
.my-note-list {
  &__item {
    @include padding(12px);
    background-color: $c-white;

    &+& {
      margin-top: 12px;
    }
  }
}

.cover-delete-wrap {
  @include flex(space-between);

}

.cover-name {
  @include flex();
  &__cover {
    width: 30px;
    height: 30px;
  }

  &__name {
    margin-left: 12px;
    @include font-style($c-black, 14, 600, 0.7px, 26px);
  }
}

.delete {
}

.note-time-edit {
  margin-top: 12px;
  @include flex(space-between, flex-end);
}

.note-time {
  &__note-text {
    @include font-style($c-assist4, 14, normal, 0.8px, 26px);
  }

  &__time-text {
    @include font-style($c-assist4, 12, normal, 0.6px, 18px);
  }
}

.edit {
  @include padding(0 10px);
  @include flex(center);
  display: inline-block;
  background-color: $c-assist15;
  height: 26px;
  border-radius: 13px;
  cursor: pointer;

  &__text {
    @include font-style($c-white, 14, normal, 0.7px, 26px);
  }
}
</style>
