<template>
  <div class="ly-full-header-layout">
    <header class="ly-fh__header">
      <menu-comp @handleMyMusicExpand="handleMyMusicExpand" />
    </header>
    <div class="ly-fh__ab-wrapper">
      <div class="ly-fh__ab-wrapper__body" :style="bodyBackgroundImage" id="scrollEle" ref="scrollEle">
        <router-view></router-view>
      </div>

      <div class="ly-fh__ab-wrapper__bottom">
        <site-map v-if="!ishideSiteMap" />
      </div>
    </div>

    <div class="my-music-expand-wrap" :class="{ 'my-music-expand-wrap--active': myMusicExpand }">
      <my-music-expand v-model:myMusicExpand="myMusicExpand" />
    </div>
  </div>
</template>
<script>
import {
  defineComponent, ref, computed, watch,
} from 'vue';
import { useRoute } from 'vue-router';
// 避免與原生 html tag 誤會
import MenuComp from '@/layout/Menu.vue';
import SiteMap from '@/views/Home/SiteMap.vue';
import MyMusicExpand from '@/layout/MyMusicExpand.vue';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'LyWhiteHeader',
  components: {
    MenuComp,
    SiteMap,
    MyMusicExpand,
  },
  setup() {
    const route = useRoute();
    const myMusicExpand = ref(false);
    // 登入 / 註冊 / 忘記密碼之類的頁面不需要
    const siteStore = useSiteStore();
    const scrollEle = ref(null);

    const handleMyMusicExpand = (status) => {
      myMusicExpand.value = status;
    };

    const backgroundImage = computed(() => siteStore.backgroundImage);
    const ishideSiteMap = computed(() => [
      'sign-in',
      'sign-up',
      'password-recovery',
      'search',
      'explore-themes-info',
      'explore-albums-info',
      'explore-playlists-info',
    ].includes(route.name));

    const bodyBackgroundImage = computed(() => {
      if (backgroundImage.value) {
        return { 'background-image': `url(${backgroundImage.value})` };
      }

      return {};
    });

    watch(scrollEle, () => {
      siteStore.setScrollEle(scrollEle.value);
    });

    return {
      ishideSiteMap,
      handleMyMusicExpand,
      myMusicExpand,
      bodyBackgroundImage,
      scrollEle,
    };
  },
});
</script>

<style lang="scss" scoped>
.ly-full-header-layout {
  width: 100%;
  min-height: 100%;
  overflow: auto;
  @include flex(flex-start, flex-start, column);
}

.ly-fh {
  &__header {
    width: 100%;
    flex: none;
    height: 60px;
    position: fixed;
    z-index: $zi-fixed-header;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  &__ab-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    @include flex(flex-start, flex-start, column);
    overflow: auto;

    &__body {
      flex: 1;
      width: 100%;
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
    }

    &__bottom {
      width: 100%;
      flex: none;
      height: auto;
    }
  }
}

.my-music-expand-wrap {
  display: none;
}

@media screen and (min-width: $tablet) {
  .ly-fh {
    &__header {
      height: 80px;
    }
  }

  .my-music-expand-wrap {
    // transform: ;
    position: relative;
    height: calc(100% - 80px);
    width: 100%;
    @include position(tr, 80px, 0);
    z-index: $zi-my-music-expand;
    // transform: translateX(500px);
    display: block;
    transition: 0.3s;
    animation: fadeOut 0.4s forwards;

    &--active {
      // transform: translateX(0px);
      animation: fadeIn 0.3s forwards;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    // left: 80px;
  }
  to {
    opacity: 1;
    // left: 80px;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    left: 0%;
  }

  99% {
    left: 0%;
  }

  100% {
    opacity: 0;
    left: 100%;
  }
}

</style>
